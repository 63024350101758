import { createStore } from 'vuex'

export default createStore({
  state: {
    menu_open :false,
    cookies_display : true,
    PROJECT_FOCUS : 1,
    GA : true,
    PROJECTS : [
      { 
        id : 1,
        logo : require('@/assets/projects/1664/logo.png'),
        footer : require('@/assets/projects/1664/mini.png'),
        img : require('@/assets/projects/1664/mockup.png'),
        filter : 1
      },
      { 
        id : 35,
        logo : require('@/assets/projects/Aqua-Parc/logo.png'),
        footer : require('@/assets/projects/Aqua-Parc/mini.jpg'),
        img : require('@/assets/projects/Aqua-Parc/mockup.png'),
        filter : 2
      },
      { 
        id : 2,
        logo : require('@/assets/projects/Badoit/logo.png'),
        footer : require('@/assets/projects/Badoit/mini.png'),
        img : require('@/assets/projects/Badoit/mockup.png'),
        filter : 1
      },
      { 
        id : 3,
        logo : require('@/assets/projects/Barilla/logo.png'),
        footer : require('@/assets/projects/Barilla/mini.png'),
        img : require('@/assets/projects/Barilla/mockup.png'),
        filter : 1
      },
      { 
        id : 4,
        logo : require('@/assets/projects/Canadou/logo.png'),
        footer : require('@/assets/projects/Canadou/mini.png'),
        img : require('@/assets/projects/Canadou/mockup.png'),
        filter : 1
      },
      { 
        id : 5,
        logo : require('@/assets/projects/Captain-Morgan/logo.png'),
        footer : require('@/assets/projects/Captain-Morgan/mini.png'),
        img : require('@/assets/projects/Captain-Morgan/mockup.png'),
        filter : 1
      },
      { 
        id : 6,
        logo : require('@/assets/projects/Cardhu/logo.png'),
        footer : require('@/assets/projects/Cardhu/mini.png'),
        img : require('@/assets/projects/Cardhu/mockup.png'),
        filter : 1
      },
      { 
        id : 7,
        logo : require('@/assets/projects/Champomy/logo.png'),
        footer : require('@/assets/projects/Champomy/mini.png'),
        img : require('@/assets/projects/Champomy/mockup.png'),
        filter : 1
      },
      { 
        id : 8,
        logo : require('@/assets/projects/Danette/logo.png'),
        footer : require('@/assets/projects/Danette/mini.png'),
        img : require('@/assets/projects/Danette/mockup.png'),
        filter : 1
      },
      { 
        id : 9,
        logo : require('@/assets/projects/Danette-vegetale/logo.png'),
        footer : require('@/assets/projects/Danette-vegetale/mini.png'),
        img : require('@/assets/projects/Danette-vegetale/mockup.png'),
        filter : 1
      },
      { 
        id : 10,
        logo : require('@/assets/projects/Danone-bio/logo.png'),
        footer : require('@/assets/projects/Danone-bio/mini.png'),
        img : require('@/assets/projects/Danone-bio/mockup.png'),
        filter : 1
      },
      { 
        id : 11,
        logo : require('@/assets/projects/Danonino/logo.png'),
        footer : require('@/assets/projects/Danonino/mini.png'),
        img : require('@/assets/projects/Danonino/mockup.png'),
        filter : 1
      },
      { 
        id : 12,
        logo : require('@/assets/projects/Dartigny/logo.png'),
        footer : require('@/assets/projects/Dartigny/mini.png'),
        img : require('@/assets/projects/Dartigny/mockup.png'),
        filter : 1
      },
      { 
        id : 36,
        logo : require('@/assets/projects/Dicaire/logo.png'),
        footer : require('@/assets/projects/Dicaire/mini.jpg'),
        img : require('@/assets/projects/Dicaire/mockup.png'),
        filter : 2
      },
      { 
        id : 37,
        logo : require('@/assets/projects/Espace360/logo.png'),
        footer : require('@/assets/projects/Espace360/mini.jpg'),
        img : require('@/assets/projects/Espace360/mockup.png'),
        filter : 2
      },
      { 
        id : 13,
        logo : require('@/assets/projects/Evian/logo.png'),
        footer : require('@/assets/projects/Evian/mini.png'),
        img : require('@/assets/projects/Evian/mockup.png'),
        filter : 1
      },
      { 
        id : 38,
        logo : require('@/assets/projects/Fatals-picards/logo.png'),
        footer : require('@/assets/projects/Fatals-picards/mini.jpg'),
        img : require('@/assets/projects/Fatals-picards/mockup.png'),
        filter : 2
      },
      { 
        id : 39,
        logo : require('@/assets/projects/FFR1/logo.png'),
        footer : require('@/assets/projects/FFR1/mini.jpg'),
        img : require('@/assets/projects/FFR1/mockup.png'),
        filter : 2
      },
      { 
        id : 40,
        logo : require('@/assets/projects/FFR2/logo.png'),
        footer : require('@/assets/projects/FFR2/mini.jpg'),
        img : require('@/assets/projects/FFR2/mockup.png'),
        filter : 2
      },
      { 
        id : 14,
        logo : require('@/assets/projects/Fjord/logo.png'),
        footer : require('@/assets/projects/Fjord/mini.png'),
        img : require('@/assets/projects/Fjord/mockup.png'),
        filter : 1
      },
      { 
        id : 52,
        logo : require('@/assets/projects/France7/logo.png'),
        footer : require('@/assets/projects/France7/mini.png'),
        img : require('@/assets/projects/France7/mockup.png'),
        filter : 2
      },
      { 
        id : 15,
        logo : require('@/assets/projects/Fuzetea/logo.png'),
        footer : require('@/assets/projects/Fuzetea/mini.png'),
        img : require('@/assets/projects/Fuzetea/mockup.png'),
        filter : 1
      },
      { 
        id : 41,
        logo : require('@/assets/projects/Gary/logo.png'),
        footer : require('@/assets/projects/Gary/mini.jpg'),
        img : require('@/assets/projects/Gary/mockup.png'),
        filter : 2
      },
      { 
        id : 42,
        logo : require('@/assets/projects/GKL/logo.png'),
        footer : require('@/assets/projects/GKL/mini.jpg'),
        img : require('@/assets/projects/GKL/mockup.png'),
        filter : 2
      },
      { 
        id : 43,
        logo : require('@/assets/projects/Helios/logo.png'),
        footer : require('@/assets/projects/Helios/mini.jpg'),
        img : require('@/assets/projects/Helios/mockup.png'),
        filter : 3
      },
      { 
        id : 16,
        logo : require('@/assets/projects/JB/logo.png'),
        footer : require('@/assets/projects/JB/mini.png'),
        img : require('@/assets/projects/JB/mockup.png'),
        filter : 1
      },
      { 
        id : 17,
        logo : require('@/assets/projects/Jenlain/logo.png'),
        footer : require('@/assets/projects/Jenlain/mini.png'),
        img : require('@/assets/projects/Jenlain/mockup.png'),
        filter : 1
      },
      { 
        id : 44,
        logo : require('@/assets/projects/JO/logo.png'),
        footer : require('@/assets/projects/JO/mini.jpg'),
        img : require('@/assets/projects/JO/mockup.png'),
        filter : 2
      },
      { 
        id : 18,
        logo : require('@/assets/projects/J-Walker/logo.png'),
        footer : require('@/assets/projects/J-Walker/mini.png'),
        img : require('@/assets/projects/J-Walker/mockup.png'),
        filter : 1
      },
      { 
        id : 19,
        logo : require('@/assets/projects/Label5/logo.png'),
        footer : require('@/assets/projects/Label5/mini.png'),
        img : require('@/assets/projects/Label5/mockup.png'),
        filter : 1
      },
      { 
        id : 45,
        logo : require('@/assets/projects/Lary/logo.png'),
        footer : require('@/assets/projects/Lary/mini.jpg'),
        img : require('@/assets/projects/Lary/mockup.png'),
        filter : 2
      },
      { 
        id : 53,
        logo : require('@/assets/projects/LaserGame/logo.png'),
        footer : require('@/assets/projects/LaserGame/mini.png'),
        img : require('@/assets/projects/LaserGame/mockup.png'),
        filter : 2
      },
      { 
        id : 20,
        logo : require('@/assets/projects/M&Ms/logo.png'),
        footer : require('@/assets/projects/M&Ms/mini.png'),
        img : require('@/assets/projects/M&Ms/mockup.png'),
        filter : 1
      },
      { 
        id : 21,
        logo : require('@/assets/projects/Marie-morin/logo.png'),
        footer : require('@/assets/projects/Marie-morin/mini.png'),
        img : require('@/assets/projects/Marie-morin/mockup.png'),
        filter : 1
      },
      { 
        id : 22,
        logo : require('@/assets/projects/Mars/logo.png'),
        footer : require('@/assets/projects/Mars/mini.png'),
        img : require('@/assets/projects/Mars/mockup.png'),
        filter : 1
      },
      { 
        id : 54,
        logo : require('@/assets/projects/M-revalite/logo.png'),
        footer : require('@/assets/projects/M-revalite/mini.png'),
        img : require('@/assets/projects/M-revalite/mockup.png'),
        filter : 2
      },
      { 
        id : 23,
        logo : require('@/assets/projects/Nestle-bio/logo.png'),
        footer : require('@/assets/projects/Nestle-bio/mini.png'),
        img : require('@/assets/projects/Nestle-bio/mockup.png'),
        filter : 1
      },
      { 
        id : 24,
        logo : require('@/assets/projects/Nicolas-feuillate/logo.png'),
        footer : require('@/assets/projects/Nicolas-feuillate/mini.png'),
        img : require('@/assets/projects/Nicolas-feuillate/mockup.png'),
        filter : 1
      },
      { 
        id : 25,
        logo : require('@/assets/projects/Oikos/logo.png'),
        footer : require('@/assets/projects/Oikos/mini.png'),
        img : require('@/assets/projects/Oikos/mockup.png'),
        filter : 1
      },
      { 
        id : 26,
        logo : require('@/assets/projects/Old-nick/logo.png'),
        footer : require('@/assets/projects/Old-nick/mini.png'),
        img : require('@/assets/projects/Old-nick/mockup.png'),
        filter : 1
      },
      { 
        id : 51,
        logo : require('@/assets/projects/Orange/logo.png'),
        footer : require('@/assets/projects/Orange/mini.png'),
        img : require('@/assets/projects/Orange/mockup.png'),
        filter : 3
      },
      { 
        id : 27,
        logo : require('@/assets/projects/Orangina/logo.png'),
        footer : require('@/assets/projects/Orangina/mini.png'),
        img : require('@/assets/projects/Orangina/mockup.png'),
        filter : 1
      },
      { 
        id : 28,
        logo : require('@/assets/projects/O-Verger/logo.png'),
        footer : require('@/assets/projects/O-Verger/mini.png'),
        img : require('@/assets/projects/O-Verger/mockup.png'),
        filter : 1
      },
      { 
        id : 29,
        logo : require('@/assets/projects/Poliakov/logo.png'),
        footer : require('@/assets/projects/Poliakov/mini.png'),
        img : require('@/assets/projects/Poliakov/mockup.png'),
        filter : 1
      },
      { 
        id : 30,
        logo : require('@/assets/projects/Sodebo/logo.png'),
        footer : require('@/assets/projects/Sodebo/mini.png'),
        img : require('@/assets/projects/Sodebo/mockup.png'),
        filter : 1
      },
      { 
        id : 31,
        logo : require('@/assets/projects/Soleou/logo.png'),
        footer : require('@/assets/projects/Soleou/mini.png'),
        img : require('@/assets/projects/Soleou/mockup.png'),
        filter : 1
      },
      { 
        id : 46,
        logo : require('@/assets/projects/Stories/logo.png'),
        footer : require('@/assets/projects/Stories/mini.jpg'),
        img : require('@/assets/projects/Stories/mockup.png'),
        filter : 2
      },
      { 
        id : 47,
        logo : require('@/assets/projects/Teedup/logo.png'),
        footer : require('@/assets/projects/Teedup/mini.jpg'),
        img : require('@/assets/projects/Teedup/mockup.png'),
        filter : 2
      },
      { 
        id : 48,
        logo : require('@/assets/projects/Teedup2/logo.png'),
        footer : require('@/assets/projects/Teedup2/mini.jpg'),
        img : require('@/assets/projects/Teedup2/mockup.png'),
        filter : 2
      },
      { 
        id : 49,
        logo : require('@/assets/projects/Teedup3/logo.png'),
        footer : require('@/assets/projects/Teedup3/mini.jpg'),
        img : require('@/assets/projects/Teedup3/mockup.png'),
        filter : 2
      },
      { 
        id : 32,
        logo : require('@/assets/projects/Tropicana/logo.png'),
        footer : require('@/assets/projects/Tropicana/mini.png'),
        img : require('@/assets/projects/Tropicana/mockup.png'),
        filter : 1
      },
      { 
        id : 33,
        logo : require('@/assets/projects/Twix/logo.png'),
        footer : require('@/assets/projects/Twix/mini.png'),
        img : require('@/assets/projects/Twix/mockup.png'),
        filter : 1
      },
      { 
        id : 50,
        logo : require('@/assets/projects/Vinci/logo.png'),
        footer : require('@/assets/projects/Vinci/mini.jpg'),
        img : require('@/assets/projects/Vinci/mockup.png'),
        filter : 3
      },
      { 
        id : 55,
        logo : require('@/assets/projects/Vinci2/logo.png'),
        footer : require('@/assets/projects/Vinci2/mini.png'),
        img : require('@/assets/projects/Vinci2/mockup.png'),
        filter : 3
      },
      { 
        id : 34,
        logo : require('@/assets/projects/Wattwiller/logo.png'),
        footer : require('@/assets/projects/Wattwiller/mini.png'),
        img : require('@/assets/projects/Wattwiller/mockup.png'),
        filter : 1
      },
      { 
        id : 57,
        logo : require('@/assets/projects/ARCADE/logo.png'),
        footer : require('@/assets/projects/ARCADE/mini.png'),
        img : require('@/assets/projects/ARCADE/mockup.png'),
        filter : 3
      },
      { 
        id : 56,
        logo : require('@/assets/projects/ISCOM/logo.png'),
        footer : require('@/assets/projects/ISCOM/mini.png'),
        img : require('@/assets/projects/ISCOM/mockup.png'),
        filter : 3
      },
      { 
        id : 58,
        logo : require('@/assets/projects/BOUYGUES_IMMOBILIER/logo.png'),
        footer : require('@/assets/projects/BOUYGUES_IMMOBILIER/mini.png'),
        img : require('@/assets/projects/BOUYGUES_IMMOBILIER/mockup.png'),
        filter : 3
      },
      { 
        id : 59,
        logo : require('@/assets/projects/STOCKERSEUL.COM/logo.png'),
        footer : require('@/assets/projects/STOCKERSEUL.COM/mini.png'),
        img : require('@/assets/projects/STOCKERSEUL.COM/mockup.png'),
        filter : 3
      },
      { 
        id : 60,
        logo : require('@/assets/projects/CLIMB_UP/logo.png'),
        footer : require('@/assets/projects/CLIMB_UP/mini.png'),
        img : require('@/assets/projects/CLIMB_UP/mockup.png'),
        filter : 2
      },
      { 
        id : 61,
        logo : require('@/assets/projects/DIBSTEUR/logo.png'),
        footer : require('@/assets/projects/DIBSTEUR/mini.png'),
        img : require('@/assets/projects/DIBSTEUR/mockup.png'),
        filter : 2
      },
      { 
        id : 62,
        logo : require('@/assets/projects/INSULA_ORCHESTRA/logo.png'),
        footer : require('@/assets/projects/INSULA_ORCHESTRA/mini.png'),
        img : require('@/assets/projects/INSULA_ORCHESTRA/mockup.png'),
        filter : 2
      },
      { 
        id : 63,
        logo : require('@/assets/projects/JE_VAIS_TAIMER/logo.png'),
        footer : require('@/assets/projects/JE_VAIS_TAIMER/mini.png'),
        img : require('@/assets/projects/JE_VAIS_TAIMER/mockup.png'),
        filter : 2
      },
      { 
        id : 64,
        logo : require('@/assets/projects/NEW_STORY/logo.png'),
        footer : require('@/assets/projects/NEW_STORY/mini.png'),
        img : require('@/assets/projects/NEW_STORY/mockup.png'),
        filter : 2
      },
      { 
        id : 65,
        logo : require('@/assets/projects/AJINOMOTO/logo.png'),
        footer : require('@/assets/projects/AJINOMOTO/mini.png'),
        img : require('@/assets/projects/AJINOMOTO/mockup.png'),
        filter : 1
      },
      { 
        id : 66,
        logo : require('@/assets/projects/APEROL/logo.png'),
        footer : require('@/assets/projects/APEROL/mini.png'),
        img : require('@/assets/projects/APEROL/mockup.png'),
        filter : 1
      },
      { 
        id : 67,
        logo : require('@/assets/projects/ARIEL/logo.png'),
        footer : require('@/assets/projects/ARIEL/mini.png'),
        img : require('@/assets/projects/ARIEL/mockup.png'),
        filter : 1
      },
      { 
        id : 68,
        logo : require('@/assets/projects/AYAM/logo.png'),
        footer : require('@/assets/projects/AYAM/mini.png'),
        img : require('@/assets/projects/AYAM/mockup.png'),
        filter : 1
      },
      { 
        id : 70,
        logo : require('@/assets/projects/BJORG_1/logo.png'),
        footer : require('@/assets/projects/BJORG_1/mini.png'),
        img : require('@/assets/projects/BJORG_1/mockup.png'),
        filter : 1
      },
      { 
        id : 71,
        logo : require('@/assets/projects/BJORG_2/logo.png'),
        footer : require('@/assets/projects/BJORG_2/mini.png'),
        img : require('@/assets/projects/BJORG_2/mockup.png'),
        filter : 1
      },
      { 
        id : 72,
        logo : require('@/assets/projects/BONDUELLE_1/logo.png'),
        footer : require('@/assets/projects/BONDUELLE_1/mini.png'),
        img : require('@/assets/projects/BONDUELLE_1/mockup.png'),
        filter : 1
      },
      { 
        id : 73,
        logo : require('@/assets/projects/BONDUELLE_2/logo.png'),
        footer : require('@/assets/projects/BONDUELLE_2/mini.png'),
        img : require('@/assets/projects/BONDUELLE_2/mockup.png'),
        filter : 1
      },
      { 
        id : 74,
        logo : require('@/assets/projects/CARAMBAR/logo.png'),
        footer : require('@/assets/projects/CARAMBAR/mini.png'),
        img : require('@/assets/projects/CARAMBAR/mockup.png'),
        filter : 1
      },
      { 
        id : 75,
        logo : require('@/assets/projects/CARTE_NOIRE/logo.png'),
        footer : require('@/assets/projects/CARTE_NOIRE/mini.png'),
        img : require('@/assets/projects/CARTE_NOIRE/mockup.png'),
        filter : 1
      },
      { 
        id : 76,
        logo : require('@/assets/projects/GLADE/logo.png'),
        footer : require('@/assets/projects/GLADE/mini.png'),
        img : require('@/assets/projects/GLADE/mockup.png'),
        filter : 1
      },
      { 
        id : 77,
        logo : require('@/assets/projects/HARRYS/logo.png'),
        footer : require('@/assets/projects/HARRYS/mini.png'),
        img : require('@/assets/projects/HARRYS/mockup.png'),
        filter : 1
      },
      { 
        id : 78,
        logo : require('@/assets/projects/ISALI/logo.png'),
        footer : require('@/assets/projects/ISALI/mini.png'),
        img : require('@/assets/projects/ISALI/mockup.png'),
        filter : 1
      },
      { 
        id : 79,
        logo : require('@/assets/projects/LENOR/logo.png'),
        footer : require('@/assets/projects/LENOR/mini.png'),
        img : require('@/assets/projects/LENOR/mockup.png'),
        filter : 1
      },
      { 
        id : 80,
        logo : require('@/assets/projects/NESCAFE/logo.png'),
        footer : require('@/assets/projects/NESCAFE/mini.png'),
        img : require('@/assets/projects/NESCAFE/mockup.png'),
        filter : 1
      },
      { 
        id : 81,
        logo : require('@/assets/projects/PAMPERS_1/logo.png'),
        footer : require('@/assets/projects/PAMPERS_1/mini.png'),
        img : require('@/assets/projects/PAMPERS_1/mockup.png'),
        filter : 1
      },
      { 
        id : 82,
        logo : require('@/assets/projects/PAMPERS_2/logo.png'),
        footer : require('@/assets/projects/PAMPERS_2/mini.png'),
        img : require('@/assets/projects/PAMPERS_2/mockup.png'),
        filter : 1
      },
      { 
        id : 83,
        logo : require('@/assets/projects/PETIT NAVIRE/logo.png'),
        footer : require('@/assets/projects/PETIT NAVIRE/mini.png'),
        img : require('@/assets/projects/PETIT NAVIRE/mockup.png'),
        filter : 1
      },
      { 
        id : 84,
        logo : require('@/assets/projects/RIANS/logo.png'),
        footer : require('@/assets/projects/RIANS/mini.png'),
        img : require('@/assets/projects/RIANS/mockup.png'),
        filter : 1
      },
      { 
        id : 85,
        logo : require('@/assets/projects/SODEBO__2/logo.png'),
        footer : require('@/assets/projects/SODEBO__2/mini.png'),
        img : require('@/assets/projects/SODEBO__2/mockup.png'),
        filter : 1
      },
      { 
        id : 86,
        logo : require('@/assets/projects/TAMPAX/logo.png'),
        footer : require('@/assets/projects/TAMPAX/mini.png'),
        img : require('@/assets/projects/TAMPAX/mockup.png'),
        filter : 1
      },
      { 
        id : 87,
        logo : require('@/assets/projects/TENA/logo.png'),
        footer : require('@/assets/projects/TENA/mini.png'),
        img : require('@/assets/projects/TENA/mockup.png'),
        filter : 1
      },
      { 
        id : 88,
        logo : require('@/assets/projects/TENA_MEN/logo.png'),
        footer : require('@/assets/projects/TENA_MEN/mini.png'),
        img : require('@/assets/projects/TENA_MEN/mockup.png'),
        filter : 1
      },
      { 
        id : 89,
        logo : require('@/assets/projects/WW/logo.png'),
        footer : require('@/assets/projects/WW/mini.png'),
        img : require('@/assets/projects/WW/mockup.png'),
        filter : 1
      },
      { 
        id : 90,
        logo : require('@/assets/projects/CUISIMEUBLE/logo.png'),
        footer : require('@/assets/projects/CUISIMEUBLE/mini.png'),
        img : require('@/assets/projects/CUISIMEUBLE/mockup.png'),
        filter : 3
      },
      { 
        id : 91,
        logo : require('@/assets/projects/FMC__AUTO/logo.png'),
        footer : require('@/assets/projects/FMC__AUTO/mini.png'),
        img : require('@/assets/projects/FMC__AUTO/mockup.png'),
        filter : 3
      },
      { 
        id : 92,
        logo : require('@/assets/projects/IBIZA_PISCINES/logo.png'),
        footer : require('@/assets/projects/IBIZA_PISCINES/mini.png'),
        img : require('@/assets/projects/IBIZA_PISCINES/mockup.png'),
        filter : 3
      },
      { 
        id : 93,
        logo : require('@/assets/projects/ORIZON_PISCINE/logo.png'),
        footer : require('@/assets/projects/ORIZON_PISCINE/mini.png'),
        img : require('@/assets/projects/ORIZON_PISCINE/mockup.png'),
        filter : 3
      },
      { 
        id : 94,
        logo : require('@/assets/projects/SRE/logo.png'),
        footer : require('@/assets/projects/SRE/mini.png'),
        img : require('@/assets/projects/SRE/mockup.png'),
        filter : 3
      },
      { 
        id : 95,
        logo : require('@/assets/projects/VINCI3/logo.png'),
        footer : require('@/assets/projects/VINCI3/mini.png'),
        img : require('@/assets/projects/VINCI3/mockup.png'),
        filter : 3
      },
      { 
        id : 96,
        logo : require('@/assets/projects/FITNESS_PARK/logo.png'),
        footer : require('@/assets/projects/FITNESS_PARK/mini.png'),
        img : require('@/assets/projects/FITNESS_PARK/mockup.png'),
        filter : 2
      },
      { 
        id : 97,
        logo : require('@/assets/projects/IN_EXTENSO_SUPERSEVENS/logo.png'),
        footer : require('@/assets/projects/IN_EXTENSO_SUPERSEVENS/mini.png'),
        img : require('@/assets/projects/IN_EXTENSO_SUPERSEVENS/mockup.png'),
        filter : 2
      },
      { 
        id : 98,
        logo : require('@/assets/projects/OLICARD/logo.png'),
        footer : require('@/assets/projects/OLICARD/mini.png'),
        img : require('@/assets/projects/OLICARD/mockup.png'),
        filter : 2
      },
      { 
        id : 99,
        logo : require('@/assets/projects/ORANGE_BLEUE/logo.png'),
        footer : require('@/assets/projects/ORANGE_BLEUE/mini.png'),
        img : require('@/assets/projects/ORANGE_BLEUE/mockup.png'),
        filter : 2
      },
      { 
        id : 100,
        logo : require('@/assets/projects/PAINTBALL_CREUSE/logo.png'),
        footer : require('@/assets/projects/PAINTBALL_CREUSE/mini.png'),
        img : require('@/assets/projects/PAINTBALL_CREUSE/mockup.png'),
        filter : 2
      },
      { 
        id : 101,
        logo : require('@/assets/projects/SUMMER_VIBRATION_FESTIVAL/logo.png'),
        footer : require('@/assets/projects/SUMMER_VIBRATION_FESTIVAL/mini.png'),
        img : require('@/assets/projects/SUMMER_VIBRATION_FESTIVAL/mockup.png'),
        filter : 2
      },
      { 
        id : 102,
        logo : require('@/assets/projects/SUMMER_VIBRATION_FESTIVAL_2/logo.png'),
        footer : require('@/assets/projects/SUMMER_VIBRATION_FESTIVAL_2/mini.png'),
        img : require('@/assets/projects/SUMMER_VIBRATION_FESTIVAL_2/mockup.png'),
        filter : 2
      },
      { 
        id : 103,
        logo : require('@/assets/projects/AGUACANA/logo.png'),
        footer : require('@/assets/projects/AGUACANA/mini.png'),
        img : require('@/assets/projects/AGUACANA/mockup.png'),
        filter : 1
      },
      { 
        id : 104,
        logo : require('@/assets/projects/ALWAYS_DISCREET/logo.png'),
        footer : require('@/assets/projects/ALWAYS_DISCREET/mini.png'),
        img : require('@/assets/projects/ALWAYS_DISCREET/mockup.png'),
        filter : 1
      },
      { 
        id : 105,
        logo : require('@/assets/projects/CANDIA_BABY_3/logo.png'),
        footer : require('@/assets/projects/CANDIA_BABY_3/mini.png'),
        img : require('@/assets/projects/CANDIA_BABY_3/mockup.png'),
        filter : 1
      },
      { 
        id : 106,
        logo : require('@/assets/projects/CASANIS/logo.png'),
        footer : require('@/assets/projects/CASANIS/mini.png'),
        img : require('@/assets/projects/CASANIS/mockup.png'),
        filter : 1
      },
      { 
        id : 107,
        logo : require('@/assets/projects/CASSEGRAIN__U/logo.png'),
        footer : require('@/assets/projects/CASSEGRAIN__U/mini.png'),
        img : require('@/assets/projects/CASSEGRAIN__U/mockup.png'),
        filter : 1
      },
      { 
        id : 108,
        logo : require('@/assets/projects/CASSEGRAIN_ITM/logo.png'),
        footer : require('@/assets/projects/CASSEGRAIN_ITM/mini.png'),
        img : require('@/assets/projects/CASSEGRAIN_ITM/mockup.png'),
        filter : 1
      },
      { 
        id : 109,
        logo : require('@/assets/projects/DANETTE_2/logo.png'),
        footer : require('@/assets/projects/DANETTE_2/mini.png'),
        img : require('@/assets/projects/DANETTE_2/mockup.png'),
        filter : 1
      },
      { 
        id : 110,
        logo : require('@/assets/projects/DANONE_CASSIS/logo.png'),
        footer : require('@/assets/projects/DANONE_CASSIS/mini.png'),
        img : require('@/assets/projects/DANONE_CASSIS/mockup.png'),
        filter : 1
      },
      { 
        id : 111,
        logo : require('@/assets/projects/DAUNAT_PAVE/logo.png'),
        footer : require('@/assets/projects/DAUNAT_PAVE/mini.png'),
        img : require('@/assets/projects/DAUNAT_PAVE/mockup.png'),
        filter : 1
      },
      { 
        id : 112,
        logo : require('@/assets/projects/DEMS/logo.png'),
        footer : require('@/assets/projects/DEMS/mini.png'),
        img : require('@/assets/projects/DEMS/mockup.png'),
        filter : 1
      },
      { 
        id : 113,
        logo : require('@/assets/projects/DILLON/logo.png'),
        footer : require('@/assets/projects/DILLON/mini.png'),
        img : require('@/assets/projects/DILLON/mockup.png'),
        filter : 1
      },
      { 
        id : 114,
        logo : require('@/assets/projects/DUVAL_LABEL_5/logo.png'),
        footer : require('@/assets/projects/DUVAL_LABEL_5/mini.png'),
        img : require('@/assets/projects/DUVAL_LABEL_5/mockup.png'),
        filter : 1
      },
      { 
        id : 115,
        logo : require('@/assets/projects/FOUR_ROSES/logo.png'),
        footer : require('@/assets/projects/FOUR_ROSES/mini.png'),
        img : require('@/assets/projects/FOUR_ROSES/mockup.png'),
        filter : 1
      },
      { 
        id : 116,
        logo : require('@/assets/projects/GLENMORANGIE/logo.png'),
        footer : require('@/assets/projects/GLENMORANGIE/mini.png'),
        img : require('@/assets/projects/GLENMORANGIE/mockup.png'),
        filter : 1
      },
      { 
        id : 117,
        logo : require('@/assets/projects/GRANOLA_BARRES/logo.png'),
        footer : require('@/assets/projects/GRANOLA_BARRES/mini.png'),
        img : require('@/assets/projects/GRANOLA_BARRES/mockup.png'),
        filter : 1
      },
      { 
        id : 118,
        logo : require('@/assets/projects/JB_2/logo.png'),
        footer : require('@/assets/projects/JB_2/mini.png'),
        img : require('@/assets/projects/JB_2/mockup.png'),
        filter : 1
      },
      { 
        id : 119,
        logo : require('@/assets/projects/JOHNNIE_WALKER/logo.png'),
        footer : require('@/assets/projects/JOHNNIE_WALKER/mini.png'),
        img : require('@/assets/projects/JOHNNIE_WALKER/mockup.png'),
        filter : 1
      },
      { 
        id : 120,
        logo : require('@/assets/projects/JOHNNIE_WALKER_JB_CARDHU/logo.png'),
        footer : require('@/assets/projects/JOHNNIE_WALKER_JB_CARDHU/mini.png'),
        img : require('@/assets/projects/JOHNNIE_WALKER_JB_CARDHU/mockup.png'),
        filter : 1
      },
      { 
        id : 121,
        logo : require('@/assets/projects/LABEL5_2/logo.png'),
        footer : require('@/assets/projects/LABEL5_2/mini.png'),
        img : require('@/assets/projects/LABEL5_2/mockup.png'),
        filter : 1
      },
      { 
        id : 122,
        logo : require('@/assets/projects/LESIEUR_ISIO/logo.png'),
        footer : require('@/assets/projects/LESIEUR_ISIO/mini.png'),
        img : require('@/assets/projects/LESIEUR_ISIO/mockup.png'),
        filter : 1
      },
      { 
        id : 123,
        logo : require('@/assets/projects/MISTER_COCKTAIL/logo.png'),
        footer : require('@/assets/projects/MISTER_COCKTAIL/mini.png'),
        img : require('@/assets/projects/MISTER_COCKTAIL/mockup.png'),
        filter : 1
      },
      { 
        id : 124,
        logo : require('@/assets/projects/NEGRITA/logo.png'),
        footer : require('@/assets/projects/NEGRITA/mini.png'),
        img : require('@/assets/projects/NEGRITA/mockup.png'),
        filter : 1
      },
      { 
        id : 125,
        logo : require('@/assets/projects/NESCAFE_2/logo.png'),
        footer : require('@/assets/projects/NESCAFE_2/mini.png'),
        img : require('@/assets/projects/NESCAFE_2/mockup.png'),
        filter : 1
      },
      { 
        id : 126,
        logo : require('@/assets/projects/OLD_NICK_2/logo.png'),
        footer : require('@/assets/projects/OLD_NICK_2/mini.png'),
        img : require('@/assets/projects/OLD_NICK_2/mockup.png'),
        filter : 1
      },
      { 
        id : 127,
        logo : require('@/assets/projects/PERLINO/logo.png'),
        footer : require('@/assets/projects/PERLINO/mini.png'),
        img : require('@/assets/projects/PERLINO/mockup.png'),
        filter : 1
      },
      { 
        id : 128,
        logo : require('@/assets/projects/RIVIERE_DU_MAT/logo.png'),
        footer : require('@/assets/projects/RIVIERE_DU_MAT/mini.png'),
        img : require('@/assets/projects/RIVIERE_DU_MAT/mockup.png'),
        filter : 1
      },
      { 
        id : 129,
        logo : require('@/assets/projects/RIVIERE_DU_MAT__2/logo.png'),
        footer : require('@/assets/projects/RIVIERE_DU_MAT__2/mini.png'),
        img : require('@/assets/projects/RIVIERE_DU_MAT__2/mockup.png'),
        filter : 1
      },
      { 
        id : 130,
        logo : require('@/assets/projects/SAINT_JAMES_COLLECTION/logo.png'),
        footer : require('@/assets/projects/SAINT_JAMES_COLLECTION/mini.png'),
        img : require('@/assets/projects/SAINT_JAMES_COLLECTION/mockup.png'),
        filter : 1
      },
      { 
        id : 131,
        logo : require('@/assets/projects/SCHWEPPES_BITTER_LEMON/logo.png'),
        footer : require('@/assets/projects/SCHWEPPES_BITTER_LEMON/mini.png'),
        img : require('@/assets/projects/SCHWEPPES_BITTER_LEMON/mockup.png'),
        filter : 1
      },
      { 
        id : 132,
        logo : require('@/assets/projects/SIR_EDWARDS/logo.png'),
        footer : require('@/assets/projects/SIR_EDWARDS/mini.png'),
        img : require('@/assets/projects/SIR_EDWARDS/mockup.png'),
        filter : 1
      },
      { 
        id : 133,
        logo : require('@/assets/projects/SOLEOU_2/logo.png'),
        footer : require('@/assets/projects/SOLEOU_2/mini.png'),
        img : require('@/assets/projects/SOLEOU_2/mockup.png'),
        filter : 1
      },
      { 
        id : 134,
        logo : require('@/assets/projects/ABGM/logo.png'),
        footer : require('@/assets/projects/ABGM/mini.png'),
        img : require('@/assets/projects/ABGM/mockup.png'),
        filter : 3
      },
      { 
        id : 135,
        logo : require('@/assets/projects/BONNEFONT/logo.png'),
        footer : require('@/assets/projects/BONNEFONT/mini.png'),
        img : require('@/assets/projects/BONNEFONT/mockup.png'),
        filter : 3
      },
      { 
        id : 136,
        logo : require('@/assets/projects/MEYNIER/logo.png'),
        footer : require('@/assets/projects/MEYNIER/mini.png'),
        img : require('@/assets/projects/MEYNIER/mockup.png'),
        filter : 3
      },
      { 
        id : 137,
        logo : require('@/assets/projects/SARL_TARSITANO/logo.png'),
        footer : require('@/assets/projects/SARL_TARSITANO/mini.png'),
        img : require('@/assets/projects/SARL_TARSITANO/mockup.png'),
        filter : 3
      },
      { 
        id : 138,
        logo : require('@/assets/projects/SCHMIDT/logo.png'),
        footer : require('@/assets/projects/SCHMIDT/mini.png'),
        img : require('@/assets/projects/SCHMIDT/mockup.png'),
        filter : 3
      },
      { 
        id : 139,
        logo : require('@/assets/projects/BEHIND_THE_BET/logo.png'),
        footer : require('@/assets/projects/BEHIND_THE_BET/mini.png'),
        img : require('@/assets/projects/BEHIND_THE_BET/mockup.png'),
        filter : 2
      },
      { 
        id : 140,
        logo : require('@/assets/projects/EDDY_DE_PRETTO/logo.png'),
        footer : require('@/assets/projects/EDDY_DE_PRETTO/mini.png'),
        img : require('@/assets/projects/EDDY_DE_PRETTO/mockup.png'),
        filter : 2
      },
      { 
        id : 141,
        logo : require('@/assets/projects/MURMURATIONS/logo.png'),
        footer : require('@/assets/projects/MURMURATIONS/mini.png'),
        img : require('@/assets/projects/MURMURATIONS/mockup.png'),
        filter : 2
      },
      { 
        id : 142,
        logo : require('@/assets/projects/ALWAYS_PADS/logo.png'),
        footer : require('@/assets/projects/ALWAYS_PADS/mini.png'),
        img : require('@/assets/projects/ALWAYS_PADS/mockup.png'),
        filter : 1
      },
      { 
        id : 143,
        logo : require('@/assets/projects/ARIEL_PODS/logo.png'),
        footer : require('@/assets/projects/ARIEL_PODS/mini.png'),
        img : require('@/assets/projects/ARIEL_PODS/mockup.png'),
        filter : 1
      },
      { 
        id : 144,
        logo : require('@/assets/projects/CASSEGRAIN/logo.png'),
        footer : require('@/assets/projects/CASSEGRAIN/mini.png'),
        img : require('@/assets/projects/CASSEGRAIN/mockup.png'),
        filter : 1
      },
      { 
        id : 145,
        logo : require('@/assets/projects/DASH_PODS/logo.png'),
        footer : require('@/assets/projects/DASH_PODS/mini.png'),
        img : require('@/assets/projects/DASH_PODS/mockup.png'),
        filter : 1
      },
      { 
        id : 146,
        logo : require('@/assets/projects/FELIX/logo.png'),
        footer : require('@/assets/projects/FELIX/mini.png'),
        img : require('@/assets/projects/FELIX/mockup.png'),
        filter : 1
      },
      { 
        id : 147,
        logo : require('@/assets/projects/LE_CHAT/logo.png'),
        footer : require('@/assets/projects/LE_CHAT/mini.png'),
        img : require('@/assets/projects/LE_CHAT/mockup.png'),
        filter : 1
      },
      { 
        id : 148,
        logo : require('@/assets/projects/DASH_LIQUIDE/logo.png'),
        footer : require('@/assets/projects/DASH_LIQUIDE/mini.png'),
        img : require('@/assets/projects/DASH_LIQUIDE/mockup.png'),
        filter : 1
      },
      { 
        id : 149,
        logo : require('@/assets/projects/BONIFAY/logo.png'),
        footer : require('@/assets/projects/BONIFAY/mini.png'),
        img : require('@/assets/projects/BONIFAY/mockup.png'),
        filter : 3
      },
      { 
        id : 150,
        logo : require('@/assets/projects/CONFORT_PLUS/logo.png'),
        footer : require('@/assets/projects/CONFORT_PLUS/mini.png'),
        img : require('@/assets/projects/CONFORT_PLUS/mockup.png'),
        filter : 3
      },
      { 
        id : 151,
        logo : require('@/assets/projects/LES_DEMENAGEURS_BRETONS/logo.png'),
        footer : require('@/assets/projects/LES_DEMENAGEURS_BRETONS/mini.png'),
        img : require('@/assets/projects/LES_DEMENAGEURS_BRETONS/mockup.png'),
        filter : 3
      },
      { 
        id : 152,
        logo : require('@/assets/projects/MOUNTAIN_COLLECTION/logo.png'),
        footer : require('@/assets/projects/MOUNTAIN_COLLECTION/mini.png'),
        img : require('@/assets/projects/MOUNTAIN_COLLECTION/mockup.png'),
        filter : 3
      },
      { 
        id : 153,
        logo : require('@/assets/projects/STOCKERSEUL/logo.png'),
        footer : require('@/assets/projects/STOCKERSEUL/mini.png'),
        img : require('@/assets/projects/STOCKERSEUL/mockup.png'),
        filter : 3
      },
      { 
        id : 154,
        logo : require('@/assets/projects/24H_MOTOS/logo.png'),
        footer : require('@/assets/projects/24H_MOTOS/mini.png'),
        img : require('@/assets/projects/24H_MOTOS/mockup.png'),
        filter : 2
      },
      { 
        id : 155,
        logo : require('@/assets/projects/DJ_BENS/logo.png'),
        footer : require('@/assets/projects/DJ_BENS/mini.png'),
        img : require('@/assets/projects/DJ_BENS/mockup.png'),
        filter : 2
      },
      { 
        id : 156,
        logo : require('@/assets/projects/FFR3/logo.png'),
        footer : require('@/assets/projects/FFR3/mini.png'),
        img : require('@/assets/projects/FFR3/mockup.png'),
        filter : 2
      },
      { 
        id : 157,
        logo : require('@/assets/projects/FFR4/logo.png'),
        footer : require('@/assets/projects/FFR4/mini.png'),
        img : require('@/assets/projects/FFR4/mockup.png'),
        filter : 2
      },
      { 
        id : 158,
        logo : require('@/assets/projects/IRONBODYFIT/logo.png'),
        footer : require('@/assets/projects/IRONBODYFIT/mini.png'),
        img : require('@/assets/projects/IRONBODYFIT/mockup.png'),
        filter : 2
      },
      { 
        id : 159,
        logo : require('@/assets/projects/LES_LEGENDES_AUTREFOIS/logo.png'),
        footer : require('@/assets/projects/LES_LEGENDES_AUTREFOIS/mini.png'),
        img : require('@/assets/projects/LES_LEGENDES_AUTREFOIS/mockup.png'),
        filter : 2
      },
      { 
        id : 160,
        logo : require('@/assets/projects/BONDUELLE_ITM/logo.png'),
        footer : require('@/assets/projects/BONDUELLE_ITM/mini.png'),
        img : require('@/assets/projects/BONDUELLE_ITM/mockup.png'),
        filter : 1
      },
      { 
        id : 161,
        logo : require('@/assets/projects/BONDUELLE_SU/logo.png'),
        footer : require('@/assets/projects/BONDUELLE_SU/mini.png'),
        img : require('@/assets/projects/BONDUELLE_SU/mockup.png'),
        filter : 1
      },
      { 
        id : 162,
        logo : require('@/assets/projects/BREF/logo.png'),
        footer : require('@/assets/projects/BREF/mini.png'),
        img : require('@/assets/projects/BREF/mockup.png'),
        filter : 1
      },
      { 
        id : 163,
        logo : require('@/assets/projects/BREWDOG/logo.png'),
        footer : require('@/assets/projects/BREWDOG/mini.png'),
        img : require('@/assets/projects/BREWDOG/mockup.png'),
        filter : 1
      },
      { 
        id : 164,
        logo : require('@/assets/projects/CANDIA/logo.png'),
        footer : require('@/assets/projects/CANDIA/mini.png'),
        img : require('@/assets/projects/CANDIA/mockup.png'),
        filter : 1
      },
      { 
        id : 165,
        logo : require('@/assets/projects/CRAZY_TIGER/logo.png'),
        footer : require('@/assets/projects/CRAZY_TIGER/mini.png'),
        img : require('@/assets/projects/CRAZY_TIGER/mockup.png'),
        filter : 1
      },
      { 
        id : 166,
        logo : require('@/assets/projects/PAMPERS_HARMONIE/logo.png'),
        footer : require('@/assets/projects/PAMPERS_HARMONIE/mini.png'),
        img : require('@/assets/projects/PAMPERS_HARMONIE/mockup.png'),
        filter : 1
      },
      { 
        id : 167,
        logo : require('@/assets/projects/PASQUIER/logo.png'),
        footer : require('@/assets/projects/PASQUIER/mini.png'),
        img : require('@/assets/projects/PASQUIER/mockup.png'),
        filter : 1
      },
      { 
        id : 168,
        logo : require('@/assets/projects/SCHWARZKOPF/logo.png'),
        footer : require('@/assets/projects/SCHWARZKOPF/mini.png'),
        img : require('@/assets/projects/SCHWARZKOPF/mockup.png'),
        filter : 1
      },
      { 
        id : 169,
        logo : require('@/assets/projects/SODEBO3/logo.png'),
        footer : require('@/assets/projects/SODEBO3/mini.png'),
        img : require('@/assets/projects/SODEBO3/mockup.png'),
        filter : 1
      },
      { 
        id : 170,
        logo : require('@/assets/projects/SODEBO4/logo.png'),
        footer : require('@/assets/projects/SODEBO4/mini.png'),
        img : require('@/assets/projects/SODEBO4/mockup.png'),
        filter : 1
      },
      { 
        id : 171,
        logo : require('@/assets/projects/SODEBO5/logo.png'),
        footer : require('@/assets/projects/SODEBO5/mini.png'),
        img : require('@/assets/projects/SODEBO5/mockup.png'),
        filter : 1
      },
      { 
        id : 172,
        logo : require('@/assets/projects/TRESOR_DE_DIEUX/logo.png'),
        footer : require('@/assets/projects/TRESOR_DE_DIEUX/mini.png'),
        img : require('@/assets/projects/TRESOR_DE_DIEUX/mockup.png'),
        filter : 1
      },
      { 
        id : 173,
        logo : require('@/assets/projects/CERAMIC_84/logo.png'),
        footer : require('@/assets/projects/CERAMIC_84/mini.png'),
        img : require('@/assets/projects/CERAMIC_84/mockup.png'),
        filter : 3
      },
      { 
        id : 174,
        logo : require('@/assets/projects/OPTIC_2000/logo.png'),
        footer : require('@/assets/projects/OPTIC_2000/mini.png'),
        img : require('@/assets/projects/OPTIC_2000/mockup.png'),
        filter : 3
      },
      { 
        id : 175,
        logo : require('@/assets/projects/24H_KARTING/logo.png'),
        footer : require('@/assets/projects/24H_KARTING/mini.png'),
        img : require('@/assets/projects/24H_KARTING/mockup.png'),
        filter : 2
      },
      { 
        id : 176,
        logo : require('@/assets/projects/24H_KARTING_2/logo.png'),
        footer : require('@/assets/projects/24H_KARTING_2/mini.png'),
        img : require('@/assets/projects/24H_KARTING_2/mockup.png'),
        filter : 2
      },
      { 
        id : 177,
        logo : require('@/assets/projects/BOL_DOR/logo.png'),
        footer : require('@/assets/projects/BOL_DOR/mini.png'),
        img : require('@/assets/projects/BOL_DOR/mockup.png'),
        filter : 2
      },
      { 
        id : 178,
        logo : require('@/assets/projects/CLIM_UP_2/logo.png'),
        footer : require('@/assets/projects/CLIM_UP_2/mini.png'),
        img : require('@/assets/projects/CLIM_UP_2/mockup.png'),
        filter : 2
      },
      { 
        id : 179,
        logo : require('@/assets/projects/LA_MARSEILLAISE/logo.png'),
        footer : require('@/assets/projects/LA_MARSEILLAISE/mini.png'),
        img : require('@/assets/projects/LA_MARSEILLAISE/mockup.png'),
        filter : 2
      },
      { 
        id : 180,
        logo : require('@/assets/projects/ALWAYS_DISCREET_2/logo.png'),
        footer : require('@/assets/projects/ALWAYS_DISCREET_2/mini.png'),
        img : require('@/assets/projects/ALWAYS_DISCREET_2/mockup.png'),
        filter : 1
      },
      { 
        id : 181,
        logo : require('@/assets/projects/ARIEL_2/logo.png'),
        footer : require('@/assets/projects/ARIEL_2/mini.png'),
        img : require('@/assets/projects/ARIEL_2/mockup.png'),
        filter : 1
      },
      { 
        id : 182,
        logo : require('@/assets/projects/BJORG_3/logo.png'),
        footer : require('@/assets/projects/BJORG_3/mini.png'),
        img : require('@/assets/projects/BJORG_3/mockup.png'),
        filter : 1
      },
      { 
        id : 183,
        logo : require('@/assets/projects/BORDEAU_CHESNEL/logo.png'),
        footer : require('@/assets/projects/BORDEAU_CHESNEL/mini.png'),
        img : require('@/assets/projects/BORDEAU_CHESNEL/mockup.png'),
        filter : 1
      },
      { 
        id : 184,
        logo : require('@/assets/projects/BREWDOG2/logo.png'),
        footer : require('@/assets/projects/BREWDOG2/mini.png'),
        img : require('@/assets/projects/BREWDOG2/mockup.png'),
        filter : 1
      },
      { 
        id : 185,
        logo : require('@/assets/projects/CAPTAIN_MORGAN2/logo.png'),
        footer : require('@/assets/projects/CAPTAIN_MORGAN2/mini.png'),
        img : require('@/assets/projects/CAPTAIN_MORGAN2/mockup.png'),
        filter : 1
      },
      { 
        id : 186,
        logo : require('@/assets/projects/CASA_AZZURRA/logo.png'),
        footer : require('@/assets/projects/CASA_AZZURRA/mini.png'),
        img : require('@/assets/projects/CASA_AZZURRA/mockup.png'),
        filter : 1
      },
      { 
        id : 187,
        logo : require('@/assets/projects/COLUMBUS/logo.png'),
        footer : require('@/assets/projects/COLUMBUS/mini.png'),
        img : require('@/assets/projects/COLUMBUS/mockup.png'),
        filter : 1
      },
      { 
        id : 188,
        logo : require('@/assets/projects/FRISKIES/logo.png'),
        footer : require('@/assets/projects/FRISKIES/mini.png'),
        img : require('@/assets/projects/FRISKIES/mockup.png'),
        filter : 1
      },
      { 
        id : 189,
        logo : require('@/assets/projects/GARBIT/logo.png'),
        footer : require('@/assets/projects/GARBIT/mini.png'),
        img : require('@/assets/projects/GARBIT/mockup.png'),
        filter : 1
      },
      { 
        id : 190,
        logo : require('@/assets/projects/JOKER/logo.png'),
        footer : require('@/assets/projects/JOKER/mini.png'),
        img : require('@/assets/projects/JOKER/mockup.png'),
        filter : 1
      },
      { 
        id : 191,
        logo : require('@/assets/projects/LA_FOURNEE_DOREE/logo.png'),
        footer : require('@/assets/projects/LA_FOURNEE_DOREE/mini.png'),
        img : require('@/assets/projects/LA_FOURNEE_DOREE/mockup.png'),
        filter : 1
      },
      { 
        id : 192,
        logo : require('@/assets/projects/MIEL_APICULTEUR/logo.png'),
        footer : require('@/assets/projects/MIEL_APICULTEUR/mini.png'),
        img : require('@/assets/projects/MIEL_APICULTEUR/mockup.png'),
        filter : 1
      },
      { 
        id : 193,
        logo : require('@/assets/projects/NURISHH/logo.png'),
        footer : require('@/assets/projects/NURISHH/mini.png'),
        img : require('@/assets/projects/NURISHH/mockup.png'),
        filter : 1
      },
      { 
        id : 194,
        logo : require('@/assets/projects/PLANETE_CHIENS/logo.png'),
        footer : require('@/assets/projects/PLANETE_CHIENS/mini.png'),
        img : require('@/assets/projects/PLANETE_CHIENS/mockup.png'),
        filter : 2
      },
      { 
        id : 195,
        logo : require('@/assets/projects/PULCO/logo.png'),
        footer : require('@/assets/projects/PULCO/mini.png'),
        img : require('@/assets/projects/PULCO/mockup.png'),
        filter : 1
      },
      { 
        id : 196,
        logo : require('@/assets/projects/PULCO_2/logo.png'),
        footer : require('@/assets/projects/PULCO_2/mini.png'),
        img : require('@/assets/projects/PULCO_2/mockup.png'),
        filter : 1
      },
      { 
        id : 197,
        logo : require('@/assets/projects/REVLON/logo.png'),
        footer : require('@/assets/projects/REVLON/mini.png'),
        img : require('@/assets/projects/REVLON/mockup.png'),
        filter : 1
      },
      { 
        id : 198,
        logo : require('@/assets/projects/VICO/logo.png'),
        footer : require('@/assets/projects/VICO/mini.png'),
        img : require('@/assets/projects/VICO/mockup.png'),
        filter : 1
      },
      { 
        id : 199,
        logo : require('@/assets/projects/VICO_2/logo.png'),
        footer : require('@/assets/projects/VICO_2/mini.png'),
        img : require('@/assets/projects/VICO_2/mockup.png'),
        filter : 1
      },

    ]
  },
  mutations: {
    setProject(state, id) {
      state.PROJECT_FOCUS = id;
    },
    closeCookies(state) {
      state.cookies_display = false;
    },
    openCookies(state) {
      state.cookies_display = true;
    }
  },
  actions: {
  },
  modules: {
  }
})
